/*Styles from https://cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.css*/
.ri-arrow-down-circle-fill:before {
  content: '\ea49';
}
.ri-arrow-down-circle-line:before {
  content: '\ea4a';
}
.ri-arrow-down-fill:before {
  content: '\ea4b';
}
.ri-arrow-down-line:before {
  content: '\ea4c';
}
.ri-arrow-down-s-fill:before {
  content: '\ea4d';
}
.ri-arrow-down-s-line:before {
  content: '\ea4e';
}
.ri-arrow-drop-down-fill:before {
  content: '\ea4f';
}
.ri-arrow-drop-down-line:before {
  content: '\ea50';
}
.ri-arrow-drop-left-fill:before {
  content: '\ea51';
}
.ri-arrow-drop-left-line:before {
  content: '\ea52';
}
.ri-arrow-drop-right-fill:before {
  content: '\ea53';
}
.ri-arrow-drop-right-line:before {
  content: '\ea54';
}
.ri-arrow-drop-up-fill:before {
  content: '\ea55';
}
.ri-arrow-drop-up-line:before {
  content: '\ea56';
}
.ri-arrow-go-back-fill:before {
  content: '\ea57';
}
.ri-arrow-go-back-line:before {
  content: '\ea58';
}
.ri-arrow-go-forward-fill:before {
  content: '\ea59';
}
.ri-arrow-go-forward-line:before {
  content: '\ea5a';
}
.ri-arrow-left-circle-fill:before {
  content: '\ea5b';
}
.ri-arrow-left-circle-line:before {
  content: '\ea5c';
}
.ri-arrow-left-down-fill:before {
  content: '\ea5d';
}
.ri-arrow-left-down-line:before {
  content: '\ea5e';
}
.ri-arrow-left-fill:before {
  content: '\ea5f';
}
.ri-arrow-left-line:before {
  content: '\ea60';
}
.ri-arrow-left-right-fill:before {
  content: '\ea61';
}
.ri-arrow-left-right-line:before {
  content: '\ea62';
}
.ri-arrow-left-s-fill:before {
  content: '\ea63';
}
.ri-arrow-left-s-line:before {
  content: '\ea64';
}
.ri-arrow-left-up-fill:before {
  content: '\ea65';
}
.ri-arrow-left-up-line:before {
  content: '\ea66';
}
.ri-arrow-right-circle-fill:before {
  content: '\ea67';
}
.ri-arrow-right-circle-line:before {
  content: '\ea68';
}
.ri-arrow-right-down-fill:before {
  content: '\ea69';
}
.ri-arrow-right-down-line:before {
  content: '\ea6a';
}
.ri-arrow-right-fill:before {
  content: '\ea6b';
}
.ri-arrow-right-line:before {
  content: '\ea6c';
}
.ri-arrow-right-s-fill:before {
  content: '\ea6d';
}
.ri-arrow-right-s-line:before {
  content: '\ea6e';
}
.ri-arrow-right-up-fill:before {
  content: '\ea6f';
}
.ri-arrow-right-up-line:before {
  content: '\ea70';
}
.ri-arrow-up-circle-fill:before {
  content: '\ea71';
}
.ri-arrow-up-circle-line:before {
  content: '\ea72';
}
.ri-arrow-up-down-fill:before {
  content: '\ea73';
}
.ri-arrow-up-down-line:before {
  content: '\ea74';
}
.ri-arrow-up-fill:before {
  content: '\ea75';
}
.ri-arrow-up-line:before {
  content: '\ea76';
}
.ri-arrow-up-s-fill:before {
  content: '\ea77';
}
.ri-arrow-up-s-line:before {
  content: '\ea78';
}
.ri-more-2-fill:before {
  content: '\22EE';
}
.ri-more-2-line:before {
  content: '\22EE';
}
