.p-button {
  @apply border-primary-2 bg-primary-2 transition-colors focus:outline-none focus:ring-primary-1;
}
.p-button:enabled {
  @apply hover:border-primary-2 hover:bg-primary-2 active:border-primary-2 active:bg-primary-2;
}
.p-inputtext:enabled:hover {
  @apply border-primary-2;
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  @apply bg-primary-5 text-primary-2;
}
.p-checkbox .p-checkbox-box.p-highlight {
  @apply bg-primary-2;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  @apply border-primary-2 bg-primary-2;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  @apply bg-primary-2;
}
.p-radiobutton {
  @apply aspect-square h-5 w-5;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  @apply border-primary-2 bg-transparent;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  @apply border-primary-2;
}

.p-calendar .p-button {
  @apply mx-1 h-7 flex-none border-none py-1 text-sm font-medium leading-5 text-primary-1 hover:border-none sm:w-auto;
}

.p-inputwrapper .p-calendar {
  @apply ml-1 h-7 flex-none rounded p-0 text-sm font-medium leading-5 text-primary-1 sm:w-auto;
}

.p-calendar .p-inputtext {
  @apply m-0 flex-1 !p-0;
}
.p-calendar.p-inputwrapper {
  @apply rounded !p-0;
}
.p-calendar.p-inputwrapper .p-inputtext {
  @apply !ml-0 flex-1 border-none hover:border-none;
}

.p-calendar .p-datepicker-trigger .p-button-icon-only {
  @apply hover:bg-primary-2;
}
.p-calendar .p-inputtext:enabled:focus {
  @apply shadow-none;
}

.p-datepicker .p-datepicker-buttonbar .p-button:hover {
  @apply !border-primary-2 !bg-primary-5 !text-primary-1;
}

.p-datepicker-trigger.p-button-icon-only {
  @apply !bg-none !text-primary-1 !shadow-none;
}
