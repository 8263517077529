.tab-view li:not(.recharts-legend-item) {
  margin-left: 19px !important;
}

.tab-view ul {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  z-index: 5;
}

.chart-wrapper {
  box-sizing: border-box;
  padding: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  background-color: white;
}

.chart-wrapper ul {
  box-shadow: none !important;
}

.recharts-legend-wrapper {
  display: flex;
  min-height: 25px;
  max-height: 35% !important;
  justify-content: center;
  overflow-y: auto;
  left: 0 !important;
  bottom: 10px !important;
  width: 100% !important;
}

/*Legend of a widget should occupy less space if height is small*/
@media screen and (min-height: 550px) and (max-height: 800px) {
  .recharts-legend-wrapper {
    max-height: 25% !important;
  }
}

@media screen and (max-height: 550px) {
  .recharts-legend-wrapper {
    max-height: 15% !important;
  }
}

.widgetCard {
  min-block-size: 330px;
  padding: 8px;
}
