.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip-body,
.tooltip-content {
  font-size: 12px;
  background-color: white;
  border-radius: 8px;
  box-shadow: -1px 3px 10px 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: -1px 3px 10px 6px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: -1px 3px 10px 6px rgba(0, 0, 0, 0.05);
}

.tooltip-body li,
.tooltip-content li {
  font-size: 12px;
  font-weight: 600;
  list-style: initial;
}
