.view-name-editor__wrapper {
  display: flex;
  align-items: center;
  height: 2.25rem;
  width: 18rem;
  max-width: 18rem;
  background-color: var(--mono-1);
}
.view-name-editor {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.view-name-editor .view-name__input {
  padding: 0.25rem 0;
}

.view-name-editor .view-name__input .input-error {
  border: 1px solid var(--error);
}

.view-name-editor__leading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  aspect-ratio: 1 / 1;
  color: var(--primary-2);
}
.view-name-editor__actions {
  display: flex;
  width: 3rem;
}
.view-name-editor__wrapper p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 13rem;
}

.view-name-editor__wrapper[data-even='true'] {
  background-color: var(--gray-5);
}

.view-name-editor__wrapper:hover .action-icon {
  display: block;
}

.view-name-editor__wrapper:has(.delete-confirmation) {
  background-color: var(--error);
}

.delete-confirmation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  color: #fff;
}
.delete-confirmation__text {
  flex: 1;
  display: flex;
  gap: 0.25rem;
  padding: 0 0.5rem;
}
.delete-confirmation__actions {
  display: flex;
  width: 3rem;
}
.delete-confirmation__actions svg {
  fill: #fff; /* Set SVG fill color to white */
}

.delete-confirmation__title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 8rem;
}
.save-view-input-wrapper {
  margin-bottom: 1rem;
  min-width: 15rem;
}
