.dashboard .p-orderlist-controls button {
  @apply border  border-transparent bg-primary-1 px-5 py-2 leading-5 text-mono-1 transition-colors hover:bg-primary-2 focus:outline-none focus:ring-2 focus:ring-primary-1 focus:ring-offset-2 sm:w-auto;
}
.dashboard.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  @apply hover:bg-primary-5;
}
.dashboard.p-orderlist .p-orderlist-list .p-orderlist-item {
  @apply p-3;
}

.picklist.p-picklist .p-picklist-buttons button {
  @apply border  border-transparent bg-primary-1 px-5 py-2 leading-5 text-mono-1 transition-colors hover:bg-primary-2 focus:outline-none focus:ring-2 focus:ring-primary-1 focus:ring-offset-2 sm:w-auto;
}
