.dashboardEdit.odin.p-inputswitch {
  height: 1.5rem;
}
.dashboardEdit.odin.p-inputswitch > .p-inputswitch-slider::before {
  left: 0.2rem;
  height: 1.2rem;
  width: 1.2rem;
}
.dashboardEdit.odin.p-inputswitch.p-component {
  align-self: center;
}

.p-tooltip.p-tooltip-bottom.odin-toggle-button__tooltip {
  padding: 0px;
}
