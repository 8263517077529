.permissions-list .p-picklist-item {
  padding: 0.5rem !important;
}

.permissions-list .p-picklist-filter-container {
  padding: 0.5rem !important;
}
.permissions-list .p-picklist-filter-input {
  padding: 0.5rem !important;
}
.permissions-list .p-picklist-header {
  padding: 1rem !important;
}

.p-dialog-header.permissions-header {
  padding-bottom: 0;
}
