/* FF */
.recharts-legend-wrapper {
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}

/* Chrome, Safari, Edge */
.recharts-legend-wrapper::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.recharts-legend-wrapper::-webkit-scrollbar-thumb {
  border-color: transparent;
  border-style: solid;
  background-color: transparent;
  background-clip: padding-box;
  border-radius: 7px;
  border-width: 1px;
}

.recharts-legend-wrapper::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

.recharts-legend-wrapper::-webkit-scrollbar-corner {
  background-color: transparent;
}

@media (hover: hover) {
  .recharts-legend-wrapper:hover {
    scrollbar-color: rgba(171, 171, 171, 0.5) transparent;
  }

  .recharts-legend-wrapper:hover::-webkit-scrollbar-thumb {
    background-color: rgba(171, 171, 171, 0.5);
  }
}
