/* Workaround for https://github.com/recharts/recharts/issues/1103*/
/*vertical charts*/
g.recharts-label-list > g {
  pointer-events: none !important;
}

/*inside the horizontal bar chart*/
.barChartVertical g.recharts-label-list > text {
  pointer-events: none !important;
}

.recharts-text {
  font-family: Roboto, 'sans-serif';
}
