.prime-dropdown {
  position: relative;
  font-size: 12px;
  width: 12rem;
}
.prime-dropdown .dropdown-label {
  position: absolute;
  padding: 0.25rem;
  z-index: 1;
  left: 8px;
  top: -14px;
  color: #b2b2b2;
  font-weight: 600;
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.prime-dropdown .p-dropdown {
  width: 100%;
  align-items: center;
  border-radius: 4px;
}
.prime-dropdown:not(.p-disabled):hover {
  border-color: var(--primary-3);
}
.prime-dropdown .p-dropdown :not(.p-disabled).p-focus {
  border-color: var(--primary-3);
  box-shadow: unset;
}

.prime-dropdown .p-dropdown-label {
  padding: var(--custom-label-padding);
  font-size: 14px;
}
.prime-dropdown .p-dropdown-label.p-placeholder {
  color: #b2b2b2;
  font-weight: 200;
}
.prime-dropdown .p-dropdown-trigger {
  width: 2rem;
}
.prime-dropdown .p-dropdown-clear-icon {
  position: unset;
  top: unset;
  right: unset;
  margin-top: 0;
}

.p-dropdown-panel .p-dropdown-header {
  padding: 0.5rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  height: 2.25rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter:focus {
  border-color: var(--primary-3);
  box-shadow: unset;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter:hover {
  border-color: var(--primary-3);
  box-shadow: unset;
}

.p-dropdown-panel .p-dropdown-items {
  padding: var(--dropdown-item-padding);
  font-size: 14px;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: var(--custom-label-padding);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: var(--primary-5);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--primary-2);
}
