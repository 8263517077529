@import './styles/custom-modal.css';
@import './styles/custom-tooltip.css';
@import './styles/custom-recharts.css';
@import './styles/custom-scrollbar.css';
@import './styles/custom-prime-order-list.css';
@import './styles/home.css';
@import './styles/header-top.css';
@import './styles/widget-wizard.css';
@import './styles/custom-icons.css';
@import '../node_modules/react-dragula/dist/dragula.min.css';
@import 'react-dragula/dist/dragula.css'; /* required for drag'n'drop animations in data-grid */
@import './styles/custom-prime-order-list.css';
@import './styles/custom-prime-common.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1;
}

.no-data {
  height: 21.25rem;
  margin: auto;
}

.gu-mirror {
  list-style-type: none;
  background-color: var(--gray-5);
  border: 1px solid var(--gray-3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.gu-mirror i {
  display: none;
}
.gu-mirror.tabElement svg {
  display: none;
}

.gu-mirror.widgetCard {
  overflow: hidden;
}

.gu-unselectable.widgetCard {
  overflow: hidden;
}
.gu-transit.widgetCard {
  overflow: hidden;
}
.gu-hide.widgetCard {
  overflow: hidden;
}

/*Make sure all icons are visible in edit mode for a tab header*/
.tabElement .max-w-12 {
  max-width: fit-content;
}

.tabElement.is-active.is-editing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  padding: 0 7px;
}

.tabElement.is-active .editableTab::before {
  content: '';
  display: block;
  position: absolute;
  inset: 0;
  z-index: -1;
}

.editableTab.userLayout {
  color: var(--gray-3);
}

.tabElement.is-active .editableTab.userLayout {
  color: var(--primary-4);
}

.editableTab.publicLayout {
  color: var(--gray-1);
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/roboto/Roboto-Regular.ttf') format('truetype'),
    url('./assets/fonts/roboto/Roboto-Italic.ttf') format('truetype'),
    url('./assets/fonts/roboto/Roboto-Light.ttf') format('truetype'),
    url('./assets/fonts/roboto/Roboto-Medium.ttf') format('truetype'),
    url('./assets/fonts/roboto/Roboto-Bold.ttf') format('truetype');
}
