.modal {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 40 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.modal-body {
  background-color: white;
  border-radius: 3px;
}

.modal-content {
  border-radius: 3px;
  width: 400px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.modal-content div {
  font-size: 14px;
  color: black;
  display: inline-block;
}

.modal-content li {
  font-size: 20px;
  vertical-align: middle;
}

.modal-header {
  padding: 10px;
}

.modal-header img {
  width: 2.5%;
}
