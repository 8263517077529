widgetWizardContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#widgetWizardContent > li {
  width: 25%;
  margin: 0;
  pointer-events: none;
}
